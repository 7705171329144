import { createTheme } from "@mui/material";

import { adaptV4Theme } from "@mui/material/styles";

const lightTheme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: ["Asap", "Jaldi"].join(","),

      h1: {
        fontFamily: "Asap",
        fontWeight: 600,
        fontSize: "60px",
        overflow: "hidden",
        textAlign: "left",
        "@media screen and (max-width: 600px)": {
          textAlign: "center",
        },
      },
      h3: {
        fontFamily: "Asap",
        fontWeight: 600,
        fontSize: "24px",
        overflow: "hidden",

        "@media screen and (max-width: 600px)": {
          fontSize: "16px",
        },
      },
      h4: {
        fontFamily: "Asap",
        fontWeight: 400,
        fontSize: "16px",
        "@media screen and (max-width: 600px)": {
          fontSize: "12px",
        },
      },
      h5: {},
      h6: {
        fontFamily: "Asap",
        fontWeight: 600,
        fontSize: "16px",
        "@media screen and (max-width: 600px)": {
          fontSize: "12px",
        },
      },
      input: {
        fontFamily: "Asap",
        fontWeight: 400,
        fontSize: "14px",
        "@media screen and (max-width: 600px)": {
          fontSize: "10px,",
        },
      },
      caption: {
        fontFamily: "Jaldi",
        fontWeight: 400,
        fontSize: "22px",
        "@media screen and (max-width: 600px)": {
          fontSize: "16px",
        },
      },
    },
  })
);

lightTheme.overrides = {
  MuiToggleButton: {
    root: {
      backgroundColor: "#FFFF",
      border: "1px solid #3C4044",
      color: "black",
      height: "56px",
      "&.Mui-selected": {
        backgroundColor: "#3C4044",
        color: "white",
        zIndex: "10",
        transition: "background-color 200ms ease-in",
      },
      "@media screen and (max-width: 700px)": {
        width: "42vw",
      },
    },
  },

  MuiToggleButtonGroup: {
    root: {
      height: "56px",
      "@media screen and (max-width: 700px)": {
        margin: "0 0 25px",
        maxWidth: "85vw",
      },
    },
  },
  MuiButton: {
    root: {
      fontFamily: "Jaldi",
      fontSize: "12px",
      "@media screen and (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
};

export { lightTheme };
