import React, { Component } from "react";
import { makeStyles } from "@mui/styles";

import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";
import { withRouter } from "react-router-dom";
import { Routes } from "../../entry/routes/Routes";
import { seShowcaseImages } from "../../lib/data";

const useStyles = makeStyles(() => ({
  grid_container: {
    backgroundColor: "white",
    marginTop: "40px",
    marginBottom: "60px",
    textAlign: "center",
  },
  text_container: {
    width: "70%",
    textAlign: "center",
    alignItems: "center",
    margin: "auto",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontWeight: "500",
    fontSize: "35px",
    "@media (max-width: 480px)": {
      fontSize: "25px",
    },
    color: "black",
    padding: "0",
    textAlign: "left",
    margin: "5px",
    paddingTop: "5%",
  },
  subheading: {
    color: "black",
    paddingLeft: "1%",
    textAlign: "center",
    fontSize: "22px",
    "@media (max-width: 480px)": {
      fontSize: "16px",
    },
    fontWeight: "100",
    margin: "0",
  },
  button: {
    textTransform: "none",
    backgroundColor: "#6EA0B5",
    display: "flex",
    margin: "auto",
    "@media (max-width: 480px)": {
      margin: "0px",
      width: "50%",
    },
    marginTop: "2%",
    borderRadius: 50,
    color: "white",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      color: "#484848",
    },
  },
  picture: {
    width: "200px",
    height: "200px",
    "@media (max-width: 480px)": {
      width: "150px",
      height: "150px",
    },
    borderRadius: "50%",
    marginLeft: "40px",
  },
  seniorexec: {
    paddingTop: "10vh",
    backgroundColor: "white",
  },
  seniorExecSmall: {
    "@media (min-width: 960px)": { display: "None" },
  },
  seniorExecLarge: {
    "@media (max-width: 959px)": { display: "None" },
  },
  grid: {
    paddingLeft: "15%",
    paddingRight: "15%",
    justifyContent: "center",
    alignItems: "start",
    paddingBottom: "10vh",
  },
  image: {
    width: "100%",
    height: "100%",
    padding: "2%",
    borderRadius: "50%",
    "@media (min-width: 960px)": { textAlign: "center" },
    objectFit: "cover",
  },
  carousal: {
    alignItems: "center",
    paddingTop: "4%",
    paddingBottom: "8%",
    width: "80%",
  },
  paragraph: {
    margin: "auto",
    fontFamily: "Nunito",
    fontSize: "20px",
    "@media (max-width: 480px)": {
      fontSize: "15px",
    },
    paddingLeft: "40px",
    paddingRight: "40px",
    fontStyle: "italic",
    color: "#484848",
  },
  title: {
    fontSize: "22px",
    "@media (max-width: 480px)": {
      fontSize: "18px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: "#484848",
    width: "100%",
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

function SplitJobRoles(string) {
  return string.split(";").map((str, ind) => <p key={ind}>{str}</p>);
}

class SeniorExecGrid extends Component {
  changeToSignUp = (event) => {
    this.props.history.push(Routes.Register);
  };

  render() {
    const classes = this.props.classes;
    const images = seShowcaseImages();

    return (
      <Grid
        id="seniorexecs"
        container
        item
        alignItems="center"
        justifyContent="center"
        className={classes.grid_container}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Carousel className={classes.carousal} variant="dark">
            {images.map(function (image, i) {
              let title = SplitJobRoles(image.title);
              return (
                <Carousel.Item key={i}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Grid
                      container
                      item
                      xs={9}
                      sm={9}
                      md={4}
                      lg={3}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <p className={classes.picture}>
                        <img
                          className={classes.image}
                          src={image.photo}
                          alt="Senior Exec"
                        />
                      </p>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={9}
                      md={6}
                      lg={5}
                      spacing={2}
                      justifyContent="center"
                    >
                      <p className={classes.title}>{image.name}</p>
                      <div className={classes.paragraph}>{title}</div>
                    </Grid>
                  </Grid>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <div className={classes.text_container}>
            <p className={classes.heading}> Our Senior Executives</p>
            <p className={classes.subheading}>
              We have over 200 Senior Executives commited to the MAX Aspire
              Platform. Book your exclusive coffee chat!
            </p>
            <Button
              className={classes.button}
              variant="contained"
              onClick={this.changeToSignUp}
            >
              Join Now
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

SeniorExecGrid = withMyHook(SeniorExecGrid);
SeniorExecGrid = withRouter(SeniorExecGrid);
export default SeniorExecGrid;
