import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import { httpGet, httpPut, httpPost } from "../../lib/dataAccess";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import { Auth } from "aws-amplify";

import { AddBox, ArrowUpward } from "@mui/icons-material";

import ThumbsUp from "@mui/icons-material/ThumbUp";
import NotInterested from "@mui/icons-material/NotInterested";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

import Moment from "react-moment";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  ThumbsUp: forwardRef((props, ref) => <ThumbsUp {...props} ref={ref} />),
  NotInterested: forwardRef((props, ref) => (
    <NotInterested {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  home_page: {
    paddingLeft: "5%",
    paddingRight: "5%",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "90vh",
    marginLeft: "20px",
  },
  booking_history: {
    fontFamily: "PT Sans",
    fontSize: "20px",
    textAlign: "left",
    color: "#58595b",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "0px",
  },
  section_title: {
    width: "100%",
    fontFamily: "PT Sans",
    fontSize: "15px",
    margin: "5px",
    marginBottom: "10px",
    marginTop: "15px",
    textAlign: "left",
    color: "black",
  },
  example: {
    width: "1000px",
    height: "100px",
  },
  body: {
    fontSize: 14,
  },
  table: {
    minWidth: 700,
  },
  SeniorExecsTable: {
    padding: "8px",
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

// show a lot more information
// usecase status LinkedIn URL vets
// cognito date of birth
// to do
const COLUMNS = [
  { title: "Name", field: "name" },
  { title: "Enabled", field: "enabled" },
  { title: "Email", field: "email" },
  { title: "Company", field: "company" },
  { title: "Industry", field: "industry" },
  { title: "Resume", field: "resume" },
  { title: "Region", field: "region" },
  { title: "Country", field: "country" },
  {
    title: "Applied Date",
    field: "created_on",
    render: (rowData) => {
      // Format unix Timestamp to date time
      return (
        <Moment unix format="MMM Do YYYY, hh:mmA">
          {rowData.created_on}
        </Moment>
      );
    },
  },
];

class SeniorExecs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMutating: false,
      isLoading: true,
    };

    this.tableRef = React.createRef();
  }

  fetchSeniorExecs = async ({
    error,
    filters,
    orderBy,
    orderDirection,
    page,
    pageSize,
    search,
    totalCount,
  }) => {
    this.setState({
      isLoading: true,
    });

    const url = `users?type=MENTOR${search ? `&search=${search}` : ""}&page=${
      page + 1
    }&page_size=${pageSize}`;
    try {
      const seniorExecsRes = await httpGet(
        url,
        (await Auth.currentSession()).getIdToken().getJwtToken()
      );

      const seniorExecs = seniorExecsRes.data.users;

      // Go through every senior executive data and derive the # of applicants to fill in table
      const seniorExecsParsed = (seniorExecs || []).map((seniorExec) => {
        const { attributes } = seniorExec;
        const address = JSON.parse(attributes["address"]);

        return attributes
          ? {
              name: `${attributes.given_name} ${attributes.family_name}`,
              enabled: seniorExec.status?.toLowerCase(),
              company: attributes["custom:company"] ?? "N/A",
              industry: attributes["custom:industry"] ?? "N/A",
              resume:
                attributes["custom:resume"] !== undefined ? (
                  <a
                    href={attributes["custom:resume"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link
                  </a>
                ) : (
                  "N/A"
                ),
              region: address?.region ?? "N/A",
              country: address?.country ?? "N/A",
              email: attributes["email"] ?? "N/A",
              created_on: attributes["custom:start_date"],
            }
          : {};
      });

      return {
        totalCount: seniorExecsRes.data.totalCount,
        data: seniorExecsParsed,
        page,
      };
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  /**
   * This function will reject a senior execs application
   * @param {string} seniorExecEmail the email of the senior executive to reject
   */
  rejectSeniorExec = async (seniorExecEmail) => {
    const disableSeniorExec = {
      email: `${seniorExecEmail}`,
    };

    this.setState({ isMutating: true });
    try {
      await httpPut(
        "users/disable",
        (await Auth.currentSession()).getIdToken().getJwtToken(),
        disableSeniorExec
      );
    } finally {
      this.setState({ isMutating: false });
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  /**
   * This function will approve a senior execs application
   * @param {string} seniorExecEmail the email of the senior executive to approve
   */
  approveSeniorExec = async (seniorExecEmail, seniorExecStatus) => {
    this.setState({ isMutating: true });

    const approveSeniorExec = {
      email: `${seniorExecEmail}`,
    };

    try {
      await httpPost(
        "users/accept-se",
        (await Auth.currentSession()).getIdToken().getJwtToken(),
        approveSeniorExec
      );
    } finally {
      this.setState({ isMutating: false });
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  enableSeniorExec = async (seniorExecEmail, seniorExecStatus) => {
    const enableSeniorExec = {
      email: `${seniorExecEmail}`,
    };

    this.setState({ isMutating: true });

    try {
      await httpPut(
        "users/enable",
        (await Auth.currentSession()).getIdToken().getJwtToken(),
        enableSeniorExec
      );
    } finally {
      this.setState({ isMutating: false });
    }

    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  render() {
    // Use Moment library to format timestamp returned from API.
    Moment.globalFormat = "MMM DD, YYYY";

    // These are the actions on the left of every column in the table.
    const actions = [
      (rowData) => ({
        icon: () => <ThumbsUp />,
        disabled: this.state.isMutating,
        tooltip: "Approve Senior Executive posting",
        hidden: rowData.enabled === "confirmed",
        onClick: (event, rowData) => {
          this.approveSeniorExec(rowData.email, rowData.status);
        },
      }),
      (rowData) => ({
        icon: () => <Check />,
        tooltip: "Enable Senior Executive",
        hidden: rowData.enabled === "enabled",
        disabled: this.state.isMutating,
        onClick: (event, rowData) => {
          this.enableSeniorExec(rowData.email, rowData.status);
        },
      }),
      (rowData) => ({
        icon: () => <NotInterested />,
        tooltip: "Disable Senior Executive",
        hidden: rowData.enabled === "disabled",
        disabled: this.state.isMutating,
        onClick: (event, rowData) => {
          this.rejectSeniorExec(rowData.email);
        },
      }),
    ];
    return (
      <MaterialTable
        title="Senior Executives"
        actions={actions}
        columns={COLUMNS}
        icons={tableIcons}
        tableRef={this.tableRef}
        data={this.fetchSeniorExecs}
        options={{
          paging: true,
          pageSize: 20,
          pageSizeOptions: [20, 100, 500],
          sorting: false,
          emptyRowsWhenPaging: false,
          exportButton: true,
          exportTrue: true,
          filtering: false,
          search: true,
          debounceInterval: 500,
          rowStyle: {
            fontSize: "13px",
          },
        }}
        isLoading={this.state.isLoading}
      />
    );
  }
}

SeniorExecs = withMyHook(SeniorExecs);
export default SeniorExecs;
