import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import { httpPost } from "../../../lib/dataAccess";
import jwtDecode from "jwt-decode";
import { withSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Toolbar from "@mui/material/Toolbar";
import close from "../../Images/close.png";
import { Avatar, Card, Typography, Icon } from "@mui/material";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const customStyles = makeStyles(() => ({
  //card
  card: {
    width: "350px",
    height: "480px",
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 5%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #eeeeee",
    padding: "10px 0 10px",
    "&:hover": {
      boxShadow: "5px 10px 10px rgba(0, 0, 0, 0.25)",
    },
    "@media screen and (max-width: 600px)": {
      width: "250px",
      height: "350px",
      "&:hover": {
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .MuiTypography-h3": {
      overflow: "visible",
    },
  },
  cardLoading: {
    width: "350px",
    height: "480px",
    borderRadius: "15px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 5%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #eeeeee",
    padding: "10px 0 10px",
    "@media screen and (max-width: 600px)": {
      width: "250px",
      height: "350px",
    },
  },
  cardPicture: {
    margin: "15px 0 25px",
    width: "200px",
    height: "200px",
    "@media screen and (max-width: 600px)": {
      width: "100px",
      height: "100px",
    },
  },
  cardInfoContainer: {
    display: "flex",
    margin: "auto 25px 25px",
    justifyContent: "space-between",
    justifySelf: "flex-end",
    alignItems: "center",
    width: "80%",
  },
  cardInfoArrow: {
    "&:hover": {
      width: "2rem",
      transition: "width 100ms ease-in-out",
    },
  },
  cardInfoText: {
    width: "60%",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media screen and (max-width: 600px)": {
      width: "40%",
    },
  },

  // components
  roundButton: {
    borderRadius: "20px",
    margin: "5px 0 5px",
    "&:hover": {
      background: "#3C4044",
      boxSizing: "border-box",
      color: "white",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      padding: "25px",
    },
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    return <Component {...props} customStyles={customStyles()} />;
  };
}

class CommunityCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnect: false,
      showAccept: false,
      showLoader: false,
      connect_status: "",
      open: false,
      bio: "",
      showProfile: false,
      showAcceptDialog: false,
      showConnectDialog: false,
      showMoreDialog: false,
    };
  }

  handleClose = (event) => {
    this.setState({
      open: false,
    });
  };

  closeConnectDialog = (event) => {
    this.setState({
      showConnectDialog: false,
    });
  };

  openConnectDialog = (event) => {
    this.setState({
      showConnectDialog: true,
    });
  };

  closeAcceptDialog = (event) => {
    this.setState({ showAcceptDialog: false });
  };

  openAcceptDialog = (event) => {
    this.setState({ showAcceptDialog: true });
  };

  openBio = (event) => {
    if (this.props.data.attributes["custom:user_type"] === "MENTOR") {
      if (this.props.data.bio !== undefined && this.props.data.bio !== "") {
        this.setState({
          bio: this.props.data.bio,
          showProfile: true,
        });
        this.setState({
          open: true,
        });
      } else {
        this.setState({
          open: true,
        });
      }
    }
  };

  checkUserType = () => {
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (
      (userProfile["custom:user_type"] === "MENTOR" &&
        this.props.data.attributes["custom:user_type"] === "MENTOR") ||
      ((userProfile["custom:user_type"] === "PAID" ||
        userProfile["custom:user_type"] === "FREE" ||
        userProfile["custom:user_type"] === "MENTOR") &&
        this.props.data.attributes["custom:user_type"] !== "MENTOR")
    ) {
      if (
        jwtDecode(localStorage.getItem("idToken"))["email"] !==
        this.props.data.attributes["email"]
      ) {
        this.setState({
          showConnect: true,
        });
      } else {
        this.setState({
          connect_status: "",
        });
      }
    }
  };

  fetchConnectsRequests = async () => {
    for (let i = 0; i < this.props.requestorResponse.length; i++) {
      if (
        this.props.requestorResponse[i].requestee ===
        this.props.data.attributes.email
      ) {
        this.setState({
          showConnect: false,
          connect_status: this.props.requestorResponse[i].connection_status,
        });
      }
    }
  };

  findRequestor = () => {
    if (this.props.requesteeResponse) {
      for (let i = 0; i < this.props.requesteeResponse.length; i++) {
        if (
          this.props.requesteeResponse[i].requestor ===
          this.props.data.attributes.email
        ) {
          if (
            this.props.requesteeResponse[i].connection_status === "ACCEPTED"
          ) {
            this.setState({
              showConnect: false,
              showAccept: false,
              connect_status: this.props.requesteeResponse[i].connection_status,
            });
          } else if (
            this.props.requesteeResponse[i].connection_status === "DECLINED"
          ) {
            this.setState({
              showConnect: false,
              showAccept: false,
              connect_status: this.props.requesteeResponse[i].connection_status,
            });
          } else {
            this.setState({
              showConnect: false,
              showAccept: true,
              connect_status: this.props.requesteeResponse[i].connection_status,
            });
          }
        }
      }
    }
  };

  handleConnect = async () => {
    this.closeConnectDialog();

    this.setState({
      showLoader: true,
    });
    let connectPayload = {
      requestor: {
        email: jwtDecode(localStorage.getItem("idToken"))["email"],
        user_type: jwtDecode(localStorage.getItem("idToken"))[
          "custom:user_type"
        ],
        name:
          jwtDecode(localStorage.getItem("idToken"))["given_name"] +
          " " +
          jwtDecode(localStorage.getItem("idToken"))["family_name"],
      },
      requestee: {
        email: this.props.data.attributes["email"],
        user_type: this.props.data.attributes["custom:user_type"],
        name:
          this.props.data.attributes["given_name"] +
          " " +
          this.props.data.attributes["family_name"],
      },
      action: "",
    };
    await httpPost(
      "connect",
      (await Auth.currentSession()).getIdToken().getJwtToken(),
      connectPayload
    )
      .then((res) => {
        this.setState({
          showConnect: false,
          showLoader: false,
          connect_status: "PENDING",
        });
        this.props.enqueueSnackbar("Connection request sent!", {
          variant: "success",
        });
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        console.log(err);
        this.props.enqueueSnackbar(
          "Failed to send connection request: " + err.response.data.message,
          {
            variant: "error",
          }
        );
      });
  };

  handleAccept = async () => {
    this.closeAcceptDialog();

    this.setState({
      showLoader: true,
    });
    let acceptPayload = {
      requestor: {
        email: jwtDecode(localStorage.getItem("idToken"))["email"],
        user_type: jwtDecode(localStorage.getItem("idToken"))[
          "custom:user_type"
        ],
        name:
          jwtDecode(localStorage.getItem("idToken"))["given_name"] +
          " " +
          jwtDecode(localStorage.getItem("idToken"))["family_name"],
      },
      requestee: {
        email: this.props.data.attributes["email"],
        user_type: this.props.data.attributes["custom:user_type"],
        name:
          this.props.data.attributes["given_name"] +
          " " +
          this.props.data.attributes["family_name"],
      },
      action: "ACCEPT",
    };
    await httpPost(
      "connect",
      (await Auth.currentSession()).getIdToken().getJwtToken(),
      acceptPayload
    )
      .then((res) => {
        this.setState({
          showAccept: false,
          showLoader: false,
          connect_status: "ACCEPTED",
        });
        this.props.enqueueSnackbar("Connection request accepted!", {
          variant: "success",
        });
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        console.log(err);
        this.props.enqueueSnackbar(
          "Failed to accept connection request: " + err.response.data.message,
          {
            variant: "error",
          }
        );
      });
  };

  handleDecline = async () => {
    this.closeAcceptDialog();

    this.setState({
      showLoader: true,
    });
    let acceptPayload = {
      requestor: {
        email: jwtDecode(localStorage.getItem("idToken"))["email"],
        user_type: jwtDecode(localStorage.getItem("idToken"))[
          "custom:user_type"
        ],
        name:
          jwtDecode(localStorage.getItem("idToken"))["given_name"] +
          " " +
          jwtDecode(localStorage.getItem("idToken"))["family_name"],
      },
      requestee: {
        email: this.props.data.attributes["email"],
        user_type: this.props.data.attributes["custom:user_type"],
        name:
          this.props.data.attributes["given_name"] +
          " " +
          this.props.data.attributes["family_name"],
      },
      action: "DECLINE",
    };
    await httpPost(
      "connect",
      (await Auth.currentSession()).getIdToken().getJwtToken(),
      acceptPayload
    )
      .then((res) => {
        this.setState({
          showAccept: false,
          showLoader: false,
          connect_status: "DECLINED",
        });
        this.props.enqueueSnackbar("Connection request declined!", {
          variant: "info",
        });
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        console.log(err);
        this.props.enqueueSnackbar(
          "Failed to accept connection request: " + err.response.data.message,
          {
            variant: "error",
          }
        );
      });
  };
  stopRipple = (event) => {
    event.stopPropagation();
  };

  showMoreDialog = (event) => {
    this.setState({ showMoreDialog: true });
  };

  closeMoreDialog = (event) => {
    this.setState({ showMoreDialog: false });
  };

  componentDidMount() {
    this.checkUserType();
    this.fetchConnectsRequests();
    this.findRequestor();
  }

  returnConnectButton = () => {
    const customStyles = this.props.customStyles;
    if (!this.state.showConnect) {
      if (this.state.showAccept) {
        return (
          <Button
            variant="outlined"
            className={customStyles.roundButton}
            onClick={this.openAcceptDialog}
          >
            View Connection Request
          </Button>
        );
      } else {
        return (
          <Button
            variant="outlined"
            className={customStyles.roundButton}
            disabled
          >
            {this.state.connect_status}
          </Button>
        );
      }
    } else {
      return (
        <Button
          variant="outlined"
          className={customStyles.roundButton}
          disabled={!this.state.showConnect}
          onClick={this.openConnectDialog}
        >
          Connect
        </Button>
      );
    }
  };

  returnButtons = () => {
    const isMobile = this.props.isMobile;
    const customStyles = this.props.customStyles;
    const userInfo = this.props.data.attributes;

    if (isMobile) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "15px 0 10px",
          }}
        >
          {" "}
          {userInfo["custom:user_type"] === "MENTOR" ? (
            <Button
              variant="outlined"
              className={customStyles.roundButton}
              onClick={this.openBio}
            >
              View Profile
            </Button>
          ) : (
            <div></div>
          )}
          {this.returnConnectButton()}
        </div>
      );
    }
    return (
      <div className={customStyles.cardInfoContainer}>
        <div className={customStyles.cardInfoText}>
          <Typography variant="h6">
            {userInfo["custom:user_type"] === "MENTOR"
              ? "Senior Executive"
              : "General Member"}
          </Typography>
          <Typography variant="h6" style={{ color: "#666666" }}>
            {userInfo["custom:industry"]}
          </Typography>
        </div>
        <Button onClick={this.showMoreDialog}>
          <Icon
            className={customStyles.cardInfoArrow}
            component={FontAwesomeIcon}
            icon={faArrowRight}
          />
        </Button>
      </div>
    );
  };

  returnDialogs = () => {
    const customStyles = this.props.customStyles;

    return (
      <div>
        {/*overview dialog*/}
        <Dialog
          className={customStyles.dialog}
          open={this.state.showMoreDialog}
          onClose={this.hideMoreDialog}
        >
          {this.props.data.attributes["custom:user_type"] === "MENTOR" ? (
            <Button
              variant="outlined"
              className={customStyles.roundButton}
              onClick={this.openBio}
            >
              View Profile
            </Button>
          ) : (
            ""
          )}
          {this.returnConnectButton()}
          <Button
            onClick={this.closeMoreDialog}
            variant="outlined"
            className={customStyles.roundButton}
          >
            CLOSE
          </Button>
        </Dialog>
        {/*bio dialog */}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          // scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth={this.state.showProfile ? "md" : "xs"}
          PaperProps={{
            style: { borderRadius: 12, height: "60vh" },
          }}
        >
          <Toolbar>
            <div>
              <h2>
                {this.state.showProfile && <span>Senior Executive Bio</span>}
                {!this.state.showProfile && <span>No Profile.</span>}
              </h2>
            </div>
            <img
              onClick={this.handleClose}
              style={{ width: "14px", height: "14px", cursor: "pointer" }}
              src={close}
              alt="Close button"
            />
          </Toolbar>
          {this.state.showProfile && (
            <DialogContent dividers>
              <DialogContentText
                id="scroll-dialog-description"
                component={"span"}
              >
                <Grid
                  container
                  item
                  xs={12}
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    item
                    sm={12}
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      className={customStyles.cardPicture}
                      src={this.props.data.attributes["picture"]}
                      alt={"Community Card"}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    sm={8}
                    spacing={0}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h1>
                        <span>
                          {this.props.data.attributes.given_name}{" "}
                          {this.props.data.attributes.family_name}
                        </span>
                      </h1>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={0}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      style={{ paddingBottom: "40px" }}
                    >
                      <span>Bio:</span>
                      {this.state.bio === "" || this.state.bio === "N/A" ? (
                        "Coming Soon"
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              (this.state.bio &&
                                this.state.bio
                                  .replaceAll("↵", "\n")
                                  .replaceAll("\n", "<br/>")) ||
                              "",
                          }}
                        ></span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          )}
        </Dialog>
        {/*connect dialog */}
        <Dialog
          open={this.state.showConnectDialog}
          onClose={this.closeConnectDialog}
          aria-labelledby="alert-dialog-create"
          aria-describedby="alert-dialog-description-create"
        >
          <DialogTitle id="alert-dialog-create">
            {"Send Connection Request?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-create">
              Are you sure you want to send a connection request to{" "}
              {this.props.data.attributes["prefix"]}{" "}
              {this.props.data.attributes["given_name"]}{" "}
              {this.props.data.attributes["family_name"]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeConnectDialog}>Close</Button>
            <Button onClick={this.handleConnect} autoFocus>
              Send
            </Button>
          </DialogActions>
        </Dialog>
        {/*accept dialog */}
        <Dialog
          open={this.state.showAcceptDialog}
          onClose={this.closeAcceptDialog}
          aria-labelledby="alert-dialog-accept"
          aria-describedby="alert-dialog-description-accept"
        >
          <DialogTitle id="alert-dialog-create">
            {"Accept Connection?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-create">
              Are you sure you want to accept connection with{" "}
              {this.props.data.attributes["prefix"]}{" "}
              {this.props.data.attributes["given_name"]}{" "}
              {this.props.data.attributes["family_name"]}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAcceptDialog}>Close</Button>
            <Button onClick={this.handleDecline} autoFocus>
              Decline
            </Button>
            <Button onClick={this.handleAccept} autoFocus>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    const customStyles = this.props.customStyles;
    const userInfo = this.props.data.attributes;

    return (
      <Card
        className={customStyles.card}
        component={this.props.Grid}
        item
        xs={6}
      >
        {/*Profile Pic*/}
        <Avatar
          src={userInfo["picture"]}
          alt={"Community Chat Card"}
          className={customStyles.cardPicture}
          style={
            userInfo["custom:user_type"] === "MENTOR"
              ? { border: "4px solid #B7A35B" }
              : {}
          }
        />
        {/*Card Content*/}
        {console.log(userInfo)}
        <Typography align="center" variant="h3" style={{ maxWidth: "80%" }}>
          {userInfo["given_name"]} {userInfo["family_name"]}
        </Typography>
        <Typography
          align="center"
          variant="caption"
          style={{ color: "#666666" }}
        >
          {/* {userInfo["address"] !== undefined ? JSON.parse(userInfo["address"]).locality + ", ":  ""} */}
          {userInfo["address"] !== undefined
            ? JSON.parse(userInfo["address"]).country
            : ""}
        </Typography>
        <Typography
          align="center"
          variant="caption"
          style={{ overflow: "visible", maxWidth: "80%" }}
        >
          {userInfo["custom:position"]}
          {" at "}
          {userInfo["custom:company"]}
        </Typography>
        {/*Buttons for connecting with users */}
        {this.returnButtons()}

        {/*Dialogs more info button*/}
        {this.returnDialogs()}
      </Card>
    );
  }
}

CommunityCard = withMyHook(CommunityCard);
export default withSnackbar(CommunityCard);
