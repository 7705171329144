import { Auth } from "aws-amplify";

export const signout = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const isUserSignedIn = () => {
  if (localStorage.getItem("idToken")) {
    return true;
  } else {
    return false;
  }
};
