import React from "react";
import { Component } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { testimonials } from "../../lib/data";
import Carousel from "react-bootstrap/Carousel";

const useStyles = makeStyles((theme) => ({
  grid_container: {
    backgroundColor: "#f1f1f1",
    marginTop: "40px",
    marginBottom: "40px",
    textAlign: "center",
  },
  picture: {
    width: "200px",
    height: "200px",
    "@media (max-width: 480px)": {
      width: "150px",
      height: "150px",
    },
    justify: "left",
    borderRadius: "50%",
  },
  header: {
    fontFamily: "Nunito Sans",
    fontSize: "48px",
    "@media (max-width: 480px)": {
      fontSize: "25px",
    },
    fontWeight: "bolder",
    textAlign: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  carousal: {
    alignItems: "center",
    paddingTop: "4%",
    paddingBottom: "8%",
    backgroundColor: "#f1f1f1",
  },
  paragraph: {
    margin: "auto",
    fontFamily: "Nunito",
    fontSize: "20px",
    "@media (max-width: 480px)": {
      fontSize: "15px",
    },
    paddingLeft: "40px",
    paddingRight: "40px",
    fontStyle: "italic",
    color: "#484848",
  },
  title: {
    fontSize: "22px",
    "@media (max-width: 480px)": {
      fontSize: "18px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: "#484848",
  },
  image: {
    width: "100%",
    height: "100%",
    padding: "2%",
    borderRadius: "50%",
    "@media (min-width: 960px)": { textAlign: "center" },
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

class Testimonials extends Component {
  timer() {
    clearInterval(this.interval);
  }

  render() {
    const classes = this.props.classes;
    const quotes = testimonials();

    return (
      <Grid
        id="seniorexecs"
        container
        item
        alignItems="center"
        justifyContent="center"
        className={classes.grid_container}
      >
        <div className={classes.carousal}>
          <h1 className={classes.header}>
            <b>What our members have to say</b>
          </h1>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            id="testimonials"
          >
            <Carousel className={classes.carousal} variant="dark">
              {quotes.map(function (testimonial, i) {
                return (
                  <Carousel.Item key={i}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Grid
                        container
                        item
                        xs={9}
                        sm={9}
                        md={4}
                        lg={3}
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <p className={classes.picture}>
                          <img
                            className={classes.image}
                            src={testimonial.image_path}
                            alt={testimonial.alt_text}
                          />
                        </p>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={9}
                        md={6}
                        lg={5}
                        spacing={1}
                        justifyContent="center"
                      >
                        <p className={classes.title}>
                          {testimonial.name} - {testimonial.role} at{" "}
                          {testimonial.company}
                        </p>
                        <p className={classes.paragraph}>
                          {testimonial.testimonial}
                        </p>
                      </Grid>
                    </Grid>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Grid>
        </div>
      </Grid>
    );
  }
}

Testimonials = withMyHook(Testimonials);
export default Testimonials;
