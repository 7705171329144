import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ChatTypes from "../ChatTypes";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";
import close from "../../Images/close.png";
import Moment from "react-moment";
import "moment-timezone";
import { withSnackbar } from "notistack";
import jwtDecode from "jwt-decode";
import WorkIcon from "@mui/icons-material/Work";
import BusinessIcon from "@mui/icons-material/Business";
import EventIcon from "@mui/icons-material/Event";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

const useStyles = makeStyles(() => ({
  cardOne: {
    width: "100%",
    maxWidth: "500px",
    marginLeft: "5px",
    height: "230px",
    marginBottom: "10px",
    borderRadius: "20px",
    textAlign: "left",
    backgroundColor: "#B5A165",
    color: "white",
    boxShadow: "0px 6px 6px #00000029",
  },
  cardFour: {
    width: "100%",
    maxWidth: "500px",
    marginLeft: "5px",
    height: "230px",
    marginBottom: "10px",
    borderRadius: "20px",
    textAlign: "left",
    backgroundColor: "#455e6a",
    color: "white",
    boxShadow: "0px 6px 6px #00000029",
  },
  cardInterview: {
    width: "100%",
    maxWidth: "500px",
    marginLeft: "5px",
    // margin: 'auto',
    height: "230px",
    marginBottom: "10px",
    borderRadius: "20px",
    textAlign: "left",
    // need designs for mock interview card  "#944848"
    backgroundColor: "#558B72",
    color: "white",
  },
  cardBooked: {
    width: "100%",
    maxWidth: "500px",
    marginLeft: "5px",
    // margin: 'auto',
    height: "230px",
    marginBottom: "10px",
    borderRadius: "20px",
    textAlign: "left",
    backgroundColor: "#9D9D9D",
    color: "white",
  },
  image: {
    maxWidth: "190px",
    maxHeight: "190px",
    minWidth: "150px",
    minHeight: "150px",
    margin: "10px 20px 10px",
    "@media (max-width: 520px)": {
      width: "160px",
      height: "160px",
      marginRight: "5px",
      marginTop: "20px",
    },
    "@media (max-width: 410px)": {
      minWidth: "initial",
      minHeight: "initial",
      width: "100px",
      height: "100px",
      marginRight: "0px",
      marginTop: "20px",
    },
    borderRadius: "50%",
    display: "inline-block",
    objectFit: "cover",
  },
  image2: {
    width: "50px",
    height: "50px",
    "@media (min-width: 1125px)": {
      width: "225px",
      height: "225px",
    },
    borderRadius: "50%",
    margin: "auto",
    marginTop: "30px",
    marginLeft: "20px",
    marginRight: "20px",
    display: "inline-block",
    objectFit: "cover",
  },
  title: {
    fontFamily: "PT Sans",
    fontWeight: "bolder",
    width: "100%",
    textAlign: "left",
    paddingTop: "5px",
    fontSize: "20px",
    "@media (max-width: 520px)": {
      fontSize: "16px",
    },
    color: "white",
    margin: "0px",
    marginLeft: "5px",
    marginTop: "5px",
  },
  title2: {
    fontFamily: "PT Sans",
    fontSize: "3vw",
    "@media (min-width: 1125px)": {
      fontSize: "30px",
    },
    "@media (max-width: 640px)": {
      fontSize: "18px",
    },
    "@media (max-width: 320px)": {
      fontSize: "15px",
    },
    width: "100%",
    textAlign: "left",
    paddingTop: "5px",
    color: "#7D7D7D",
    margin: "0px",
    // marginLeft: "5px"
    marginTop: "15px",
  },
  subtitle: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    "@media (max-width: 520px)": {
      width: "80%",
      fontSize: "12px",
    },
    "@media (max-width: 320px)": {
      fontSize: "10px",
      marginTop: "3px",
    },
    width: "100%",
    textAlign: "left",
    display: "flex",
    color: "white",
    margin: "0px",
    marginLeft: "5px",
    marginTop: "5px",
  },

  //to be applied to span elements to avoid overflow
  overflowText: {
    minwidth: "170px",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  subtitle2: {
    fontSize: "16px",
    "@media (max-width: 480px)": {
      fontSize: "14px",
      marginLeft: "0px",
    },
    fontFamily: "PT Sans",
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
    color: "black",
    margin: "0px",
    marginLeft: "5px",
    marginTop: "5px",
  },
  name: {
    fontStyle: "italic",
    paddingRight: "5px",
    margin: "0px",
    marginTop: "5px",
  },
  company: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    "@media (max-width: 520px)": {
      fontSize: "10px",
    },
    width: "100%",
    textAlign: "left",
    margin: "5px",
    color: "white",
  },
  date: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    "@media (max-width: 520px)": {
      fontSize: "10px",
      marginBottom: "5px",
    },
    width: "100%",
    textAlign: "left",
    fontSize: "15px",
    color: "white",
    margin: "0px",
    float: "left",
    overflow: "hidden",
    minWidth: "180px",
  },
  date2: {
    fontFamily: "myriad-pro, sans-serif",
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
    fontSize: "15px",
    color: "black",
    margin: "0px",
    float: "left",
  },
  credits: {
    fontFamily: "myriad-pro, sans-serif",
    fontWeight: "bold",
    width: "100%",
    fontSize: "22px",
    "@media (max-width: 480px)": {
      fontSize: "16px",
    },
    textAlign: "left",
    color: "#B6A165",
    margin: "0px",
    float: "left",
  },
  booked: {
    fontFamily: "myriad-pro, sans-serif",
    fontWeight: "bold",
    textAlign: "right",
    margin: "5px",
    "@media (max-width: 320px)": {
      display: "none",
    },
    paddingTop: "5px",
    fontSize: "8px",
    color: "white",
    float: "right",
  },
  booked2: {
    fontFamily: "myriad-pro, sans-serif",
    fontWeight: "bold",
    textAlign: "right",
    margin: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    color: "black",
    float: "right",
  },
  button_container: {
    marginTop: "5px",
    alignItems: "flex-end",
    justify: "flex-end",
  },
  button: {
    fontSize: "8px",
    fontWeight: "400",
    marginLeft: "8px",
    "@media (max-width: 480px)": {
      marginLeft: "0px",
    },
    borderRadius: 50,
    backgroundColor: "white",
    color: "#58595B",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      color: "#484848",
    },
  },
  tag_container: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: "0.5px",
    borderRadius: 50,
    borderColor: "black",
    margin: "5px",
    marginLeft: "0px",
  },
  tag: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "3px",
    paddingBottom: "3px",
    left: "15px",
    right: "15px",
    float: "left",
    fontSize: "15px",
    fontWeight: "100",
    color: "black",
    display: "flex",
  },
  bar: {
    width: "90%",
    textAlign: "right",
    marginLeft: "0%",
    marginTop: "2%",
    marginBottom: "2%",
    height: 1,
    paddingBottom: "0",
  },
  container: {
    paddingTop: "5px",
  },
  company_icon: {
    width: "18px",
    height: "18px",
    marginRight: "15px",
  },
  outer_grid: {
    height: "230px",
  },
  toolbar: {
    height: "8vh",
    backgroundColor: "#455E6A",
    boxShadow: "0px 0px 0px",
    width: "100%",
  },
  closes: {
    position: "absolute",
    right: "5%",
  },

  dialogLabel: {
    fontSize: "21px",
    "@media (max-width: 480px)": {
      fontSize: "16px",
      marginTop: "5px",
    },
    margin: "0px",
    marginTop: "10px",
    color: "white",
  },

  button2: {
    textTransform: "none",
    backgroundColor: "#000000",
    marginBottom: "2%",
    marginRight: "auto",
    marginTop: "20px",
    borderRadius: 50,
    color: "#FFFFFF",
    position: "relative",
    display: "block",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      color: "#484848",
    },
    fontSize: "15px",
    fontWeight: "bold",
    fontFamily: "myriad-pro, sans-serif",
    paddingLeft: "50px",
    paddingRight: "50px",
  },

  reservedText: {
    fontFamily: "PT Sans",
    fontSize: "15px",
    "@media (max-width: 480px)": {
      fontSize: "12px",
    },
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

class CoffeeChatSelfCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      chat_status: this.props.data.chat_status,
      barDisplay: false,
      userType: jwtDecode(localStorage.getItem("idToken"))["custom:user_type"],
    };
  }

  handleClose = (event) => {
    this.setState({
      open: false,
    });
  };

  openCoffeeChat = (event) => {
    this.setState({
      open: true,
    });
  };

  dismiss = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div
        className={
          this.props.data.chat_status === "ChatStatus.RESERVED"
            ? classes.cardBooked
            : this.props.data.chat_type === ChatTypes.oneOnOne
            ? classes.cardOne
            : this.props.data.chat_type === ChatTypes.fourOnOne
            ? classes.cardFour
            : classes.cardInterview
        }
      >
        <div className={classes.container}>
          <Grid
            container
            item
            xs={12}
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
            direction="row"
            className={classes.outer_grid}
          >
            <Grid
              container
              item
              xs={6}
              spacing={0}
              alignItems="center"
              justifyContent="flex-start"
            >
              <img
                className={classes.image}
                src={this.props.data.picture}
                alt={"Coffee Chat Card"}
              />
            </Grid>

            <Grid
              container
              item
              xs={6}
              spacing={0}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <h1 className={classes.title}>
                  {this.props.data.given_name} {this.props.data.family_name}
                </h1>
                <p className={classes.subtitle}>
                  <span>
                    <WorkIcon className={classes.company_icon} />
                  </span>{" "}
                  <span className={classes.overflowText}>
                    {this.props.data["position"]}
                  </span>
                  {this.props.data.title}
                </p>

                <p className={classes.subtitle}>
                  <span>
                    <BusinessIcon className={classes.company_icon} />
                  </span>{" "}
                  <span className={classes.overflowText}>
                    {this.props.data["custom:company"]}
                  </span>
                  {this.props.data.title}
                </p>

                <p className={classes.subtitle}>
                  <span>
                    <SupervisorAccountIcon className={classes.company_icon} />
                  </span>{" "}
                  <span className={classes.overflowText}>
                    {this.props.data.chat_type === ChatTypes.oneOnOne
                      ? "One on One"
                      : this.props.data.chat_type === ChatTypes.fourOnOne
                      ? "Four on One"
                      : "Mock Interview"}
                  </span>
                </p>

                <p className={classes.subtitle}>
                  <span>
                    <EventIcon className={classes.company_icon} />
                  </span>
                  {""}

                  <span className={classes.date}>
                    {this.props.data.fixed_date ? (
                      <Moment unix local format="MMM Do YYYY, hh:mmA">
                        {this.props.data.fixed_date}
                      </Moment>
                    ) : (
                      "To be Determined"
                    )}
                  </span>
                </p>

                {/* {this.props.data &&
                  this.props.data.chat_tags &&
                  this.props.data.chat_tags.map((tag, key) => (
                    <span key={key} className={classes.tag_container}>
                      <span className={classes.tag}>{tag}</span>
                    </span>
                  ))} */}
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <hr className={classes.bar}></hr>
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  spacing={0}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <span className={classes.button_container}>
                    {this.state.userType === "MENTOR" ? (
                      <Button
                        onClick={this.openCoffeeChat}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                      >
                        <h3>
                          {this.props.data.chat_status === "ChatStatus.RESERVED"
                            ? "RESERVED"
                            : "TO BE RESERVED"}
                        </h3>
                      </Button>
                    ) : this.state.chat_status === "RESERVED" ||
                      this.state.chat_status === "RESERVED_PARTIAL" ? (
                      <Button
                        onClick={this.openCoffeeChat}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                      >
                        <h3>
                          {this.props.data.chat_status === "ChatStatus.RESERVED"
                            ? "RESERVED"
                            : "TO BE RESERVED"}
                        </h3>
                      </Button>
                    ) : this.state.chat_status === "UNRESERVED" ? (
                      <h3 className={classes.reservedText}>UNRESERVED</h3>
                    ) : (
                      <h3 className={classes.reservedText}>RESERVED</h3>
                    )}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Dialog
          className={classes.translate}
          open={this.state.open}
          onClose={this.handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth={"md"}
          PaperProps={{
            style: { borderRadius: 12 },
          }}
        >
          <Toolbar className={classes.toolbar}>
            <div>
              <h2 className={classes.dialogLabel}>Coffee Chat Details</h2>
            </div>
            <img
              onClick={this.handleClose}
              className={classes.closes}
              style={{ width: "14px", height: "14px", cursor: "pointer" }}
              src={close}
              alt="Close button"
            />
          </Toolbar>

          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              component={"span"}
            >
              <Grid
                container
                item
                xs={12}
                spacing={0}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={4}
                  spacing={0}
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <img
                    className={classes.image2}
                    src={this.props.data.picture}
                    alt={"Coffee Chat Card"}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={8}
                  spacing={0}
                  alignItems="center"
                  justifyContent="flex-start"
                  className={classes.outer_grid}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <h1 className={classes.title2}>
                      {this.props.data.chat_type === ChatTypes.oneOnOne
                        ? "One on One"
                        : this.props.data.chat_type === ChatTypes.fourOnOne
                        ? "Four on One"
                        : "Mock Interview"}
                      {this.props.data.booked ? (
                        <span className={classes.booked}>booked</span>
                      ) : (
                        ""
                      )}{" "}
                      with&nbsp;
                      <span className={classes.name2}>
                        {this.props.data.given_name}{" "}
                        {this.props.data.family_name}
                      </span>
                    </h1>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <span className={classes.subtitle2}>
                      Company: {this.props.data["custom:company"]}
                    </span>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={6}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <span className={classes.subtitle2}>
                      Title: {this.props.data["position"]}
                    </span>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={6}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <span className={classes.subtitle2}>
                      Industry: {this.props.data["industry"]}
                    </span>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={6}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <span className={classes.subtitle2}>
                      Region: {this.props.data["region"]}
                    </span>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    {this.props.data.fixed_date ? (
                      <span className={classes.subtitle2}>
                        Date:{" "}
                        <Moment unix local format="ddd, MMM Do YYYY, hh:mm A">
                          {this.props.data.fixed_date}
                        </Moment>
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    {this.props.data?.aspiring_professionals?.length > 0 ? (
                      <span className={classes.subtitle2}>
                        Registered with:{" "}
                        {this.props.data.aspiring_professionals}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <span className={classes.date2}>
                      {this.props.data.description}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                <DialogActions>
                  <Button
                    className={classes.button2}
                    variant="contained"
                    onClick={this.dismiss}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

CoffeeChatSelfCard = withMyHook(CoffeeChatSelfCard);
export default withSnackbar(CoffeeChatSelfCard);
