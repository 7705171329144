import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import CoffeeChatSelfCard from "./Cards/CoffeeChatSelfCard";
import EmptyCard from "./Cards/EmptyCard";
import Grid from "@mui/material/Grid";
import CardTypes from "./CardTypes";
import { httpGet } from "../../lib/dataAccess";
import jwtDecode from "jwt-decode";
import Skeleton from "@mui/lab/Skeleton";
import { withSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import { isUserSignedIn } from "../../lib/common";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(30),
    },
  },
  cardCoffeeLoader: {
    width: "100%",
    maxWidth: "500px",
    marginLeft: "5px",
    height: "180px",
    marginBottom: "10px",
    borderRadius: "20px",
    textAlign: "left",
    backgroundColor: "#B5A165",
    color: "white",
    boxShadow: "0px 6px 6px #00000029",
  },
  cardAppLoader: {
    width: "100%",
    maxWidth: "350px",
    height: "180px",
    borderStyle: "solid",
    borderRadius: "20px",
    backgroundColor: "#6EA0B5",
    color: "white",
    borderColor: "#6EA0B5",
    textAlign: "left",
    fontWeight: "100",
    fontFamily: "Arial",
    marginBottom: "5%",
    marginRight: "5%",
    "@media (max-width: 480px)": {
      marginRight: "0px",
    },
    boxShadow: "0px 6px 6px #00000029",
    overflow: "hidden",
  },
  cardPostLoader: {
    width: "100%",
    minHeight: "130px",
    maxWidth: "350px",
    height: "180px",
    marginBottom: "20px",
    borderRadius: "20px",
    backgroundColor: "#58595B",
    boxShadow: "0px 6px 6px #00000029",
  },
  home_page: {
    paddingLeft: "5%",
    paddingRight: "0%",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "90vh",
    marginLeft: "10px",
  },
  booking_history: {
    fontFamily: "PT Sans",
    fontSize: "30px",
    textAlign: "left",
    color: "#58595b",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  section_title: {
    width: "100%",
    fontFamily: "PT Sans",
    fontSize: "15px",
    margin: "5px",
    marginBottom: "10px",
    marginTop: "15px",
    textAlign: "left",
    color: "black",
  },
  example: {
    width: "1000px",
    height: "100px",
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

class Home extends Component {
  constructor(props) {
    super(props);

    let userType = null;
    if (isUserSignedIn()) {
      userType = jwtDecode(localStorage.getItem("idToken"))["custom:user_type"];
    }

    this.state = {
      coffee_chats: [],
      isChatsLoaded: false,
      user_type: userType,
    };
  }

  fetchChats = async () => {
    const idTokeninfo = jwtDecode(
      (await Auth.currentSession()).getIdToken().getJwtToken()
    );
    const chatsData = await httpGet(
      "chats?email=" + idTokeninfo.email,
      (await Auth.currentSession()).getIdToken().getJwtToken()
    );
    if (chatsData.data.chats !== undefined) {
      this.setState({
        isChatsLoaded: true,
        coffee_chats: chatsData.data.chats,
      });
    }
  };

  componentDidMount() {
    this.fecthData();
  }

  fecthData = () => {
    this.fetchChats();
  };

  render() {
    const classes = this.props.classes;
    return (
      <div>
        {/* <PerfectScrollbar> */}
        <div className={classes.home_page}>
          <h1 className={classes.booking_history}>Dashboard</h1>
          <Grid container alignItems="flex-start" justifyContent="flex-start">
            <Grid
              container
              item
              xs={12}
              spacing={1}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <h5 className={classes.section_title}>Coffee Chats</h5>
              {this.state.user_type === "MENTOR" ? (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <p style={{ textAlign: "left" }}>
                    Once every two to three months, your coffee chat will be
                    made available to MAX Aspiring Professionals.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Once you have been booked, the details will appear where you
                    click 'View Details'. You will also receive an email
                    notification when a Coffee Chat is booked with you by an
                    Aspiring Professional
                  </p>
                </Grid>
              ) : (
                ""
              )}

              {this.state.isChatsLoaded ? (
                this.state.coffee_chats &&
                this.state.coffee_chats.length > 0 ? (
                  this.state.coffee_chats.map((chat, key) => (
                    <Grid
                      key={key}
                      container
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      spacing={1}
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <CoffeeChatSelfCard data={chat} />
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={4}
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <EmptyCard type={CardTypes.coffeeChat} filtered={false} />
                  </Grid>
                )
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={4}
                  spacing={1}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Skeleton
                    variant="rectangular"
                    className={classes.cardCoffeeLoader}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {/* </PerfectScrollbar> */}
      </div>
    );
  }
}

Home = withMyHook(Home);
export default withSnackbar(Home);
