import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CardTypes from "../CardTypes";

const useStyles = makeStyles(() => ({
  cardCoffee: {
    width: "100%",
    maxWidth: "350px",
    height: "180px",
    marginBottom: "10px",
    borderRadius: "20px",
    backgroundColor: "#B5A165",
    color: "white",
    overflow: "hidden",
  },
  cardApp: {
    width: "100%",
    maxWidth: "350px",
    height: "180px",
    borderStyle: "solid",
    borderRadius: "20px",
    backgroundColor: "#6EA0B5",
    color: "white",
    borderColor: "#6EA0B5",
    overflow: "hidden",
  },
  cardPosting: {
    width: "100%",
    maxWidth: "350px",
    height: "180px",
    marginBottom: "20px",
    borderRadius: "20px",
    backgroundColor: "#58595B",
    overflow: "hidden",
  },
  title: {
    fontFamily: "PT Sans",
    fontWeight: "bolder",
    width: "100%",
    paddingTop: "5px",
    fontSize: "20px",
    "@media (max-width: 480px)": {
      fontSize: "15px",
    },
    color: "white",
    margin: "0px",
    marginTop: "5px",
  },
  subtitle: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    width: "100%",
    color: "white",
    margin: "0px",
    "@media (max-width: 480px)": {
      fontSize: "12px",
    },
    fontSize: "15px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  button_container: {
    alignItems: "flex-end",
    justify: "flex-end",
  },
  button: {
    fontSize: "8px",
    "@media (max-width: 320px)": {
      fontSize: "6px",
    },
    fontWeight: "400",
    borderRadius: 50,
    backgroundColor: "white",
    color: "#58595B",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      color: "#484848",
    },
  },
  container: {
    width: "95%",
    display: "inline-block",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    overflow: "hidden",
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

class EmptyCard extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <Grid
        container
        item
        xs={12}
        spacing={0}
        alignItems="center"
        justifyContent="center"
        className={
          this.props.type === CardTypes.coffeeChat ? classes.cardCoffee : null
        }
      >
        <div className={classes.container}>
          <h1 className={classes.title}>
            {this.props.filtered
              ? ""
              : this.props.type === CardTypes.coffeeChat
              ? "No booked coffee chats"
              : ""}
          </h1>
          <p className={classes.subtitle}>
            {!this.props.filtered
              ? this.props.type === CardTypes.coffeeChat
                ? "To book one, click the Coffee Chats tab above."
                : ""
              : this.props.type === CardTypes.coffeeChat
              ? "No coffee chats meet the applied filter"
              : this.props.type === CardTypes.community
              ? "No community members meet the applied filter"
              : ""}
          </p>
        </div>
      </Grid>
    );
  }
}

EmptyCard = withMyHook(EmptyCard);
export default EmptyCard;
