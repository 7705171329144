import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import CommunityCard from "./Cards/CommunityCard";
import { withRouter } from "react-router";
import { httpGet } from "../../lib/dataAccess";
import { withSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import Skeleton from "@mui/lab/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import jwtDecode from "jwt-decode";
import TextField from "@mui/material/TextField";
import Industries from "../Registration/industry";
import MenuItem from "@mui/material/MenuItem";
import EmptyCard from "./Cards/EmptyCard";
import { Routes } from "../../entry/routes/Routes";
import { isUserSignedIn } from "../../lib/common";
import ToggleButton from "@mui/lab/ToggleButton";
import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";
import { lightTheme } from "./Theme";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  Typography,
  Tooltip,
} from "@mui/material";

const IndustryLabels = [];
IndustryLabels.push("All");
for (let i = 0; i < Industries.length; ++i) {
  IndustryLabels.push(Industries[i]["name"]);
}

const customStyles = makeStyles(() => ({
  // components
  roundButton: {
    borderRadius: "20px",
    margin: "5px 0 5px",
    "&:hover": {
      background: "#3C4044",
      boxSizing: "border-box",
      color: "white",
    },
  },

  // community page
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "25px",
    "@media screen and (max-width: 700px)": {
      display: "block",
      maxWidth: "85vw",
      margin: "auto auto auto",
    },
  },
  textField: {
    width: "45vw",
    maxWidth: "600px",

    "@media screen and (max-width: 700px)": {
      margin: "0 0 25px",
      width: "85vw",
    },
  },
  page: {
    "@media screen and (min-width: 1280px)": {
      width: "1280px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const theme = lightTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
      defaultMatches: true,
    });
    return (
      <Component
        {...props}
        isMobile={isMobile}
        theme={theme}
        customStyles={customStyles()}
      />
    );
  };
}

class JobBoard extends Component {
  constructor(props) {
    super(props);

    let emailAddress = null;
    if (!isUserSignedIn()) {
      this.props.history.push(Routes.Landpage);
    } else {
      emailAddress = jwtDecode(localStorage.getItem("idToken"))["email"];
    }

    this.state = {
      // temporary - just wanted more test data to fill the page
      community_data: [],
      isCommunityLoaded: false,
      requesteeResponse: [],
      requestorResponse: [],
      currentUserEmail: emailAddress,
      industry: "",
      userType: "",
      unfilteredMembers: [],
      unfilteredMembersByUserType: [],
      hasMore: true,
      pageNum: 1,
      alignment: "SeniorExcutive",
      toggleSeniorExcutive: true,
    };
  }

  fetchConnects = async () => {
    await httpGet(
      "connect?requestee=" + this.state.currentUserEmail,
      (await Auth.currentSession()).getIdToken().getJwtToken()
    )
      .then((res) => {
        this.setState({
          requesteeResponse: res.data["connections"],
        });
      })
      .catch((err) => {
        console.log(err);
        this.props.enqueueSnackbar("Failed to fetch connections: " + err, {
          variant: "err",
        });
      });
  };

  fetchConnectRequests = async () => {
    await httpGet(
      "connect?requestor=" + this.state.currentUserEmail,
      (await Auth.currentSession()).getIdToken().getJwtToken()
    )
      .then((res) => {
        this.setState({
          requestorResponse: res.data["connections"],
        });
      })
      .catch((err) => {
        console.log(err);
        this.props.enqueueSnackbar("Failed to fetch connections: " + err, {
          variant: "err",
        });
      });
  };

  fetchUsers = async (reload = false) => {
    let url = "users?page=" + this.state.pageNum;
    let industry = this.state.industry;
    if (industry !== "" && industry !== "All") {
      url = url + "&industry=" + industry;
    }
    if (this.state.toggleSeniorExcutive) {
      url = url + "&type=MENTOR";
    } else {
      url = url + "&type=PAID,FREE";
    }

    const users = await httpGet(
      url,
      (await Auth.currentSession()).getIdToken().getJwtToken()
    ).catch((err) => {
      console.log(err);
      this.props.enqueueSnackbar("Failed to fetch users: " + err, {
        variant: "err",
      });
    });

    let community_data = this.state.community_data;

    let full = [];
    if (community_data && community_data.length > 0 && !reload) {
      full = community_data.concat(users.data.users);
    } else {
      full = users.data.users;
    }

    this.setState({
      community_data: full,
      isCommunityLoaded: true,
      unfilteredMembers: full,
      unfilteredMembersByUserType: full,
      hasMore: true, // FIXME,
      pageNum: this.state.pageNum + 1,
    });
  };

  filterMembers = async () => {
    let industry = this.state.industry;
    let filteredMembers = this.state.unfilteredMembers;

    if (industry !== "" && industry !== "All") {
      filteredMembers = this.state.unfilteredMembers.filter(
        (member) => member.attributes["custom:industry"] === this.state.industry
      );
      await this.setState({
        community_data: filteredMembers,
        pageNum: 1,
        isCommunityLoaded: false,
      });
      this.fetchUsers();
    }
  };

  filterMembersByUserType = async () => {
    let userType = this.state.userType;
    let filteredMembersByUserType = this.state.unfilteredMembersByUserType;

    if (userType !== "" && userType !== "All") {
      if (userType === "Senior Executive") {
        filteredMembersByUserType = this.state.unfilteredMembersByUserType.filter(
          (member) => member.attributes["custom:user_type"] === "MENTOR"
        );
      } else {
        filteredMembersByUserType = this.state.unfilteredMembersByUserType.filter(
          (member) => member.attributes["custom:user_type"] !== "MENTOR"
        );
      }
    }

    await this.setState({
      community_data: [],
    });

    this.setState({
      community_data: filteredMembersByUserType,
      isCommunityLoaded: true,
    });
  };

  handleIndustryChange = async (event) => {
    await this.setState({ industry: event.target.value });
    this.filterMembers();
  };

  handleUserTypeChange = async (event) => {
    await this.setState({ userType: event.target.value });
    this.filterMembersByUserType();
  };

  handleAlignment = async (event, newAlignment) => {
    await this.setState({
      alignment: newAlignment,
      toggleSeniorExcutive: !this.state.toggleSeniorExcutive,
      pageNum: 1,
    });
    this.fetchUsers(true);
  };

  returnCards = (reload) => {
    const isMobile = this.props.isMobile;
    const customStyles = this.props.customStyles;

    return (
      <InfiniteScroll
        dataLength={this.state.community_data.length}
        next={this.fetchUsers}
        hasMore={this.state.hasMore}
        loader={<h4> </h4>}
      >
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          spacing={isMobile ? 1 : 4}
          alignItems="center"
          justifyContent="left"
        >
          {this.state.isCommunityLoaded === true ? (
            this.state.community_data &&
            this.state.community_data.length > 0 ? (
              this.state.community_data.map((chat, key) => (
                <Grid key={key} container item xs={6} sm={6} md={4} lg={3}>
                  <CommunityCard
                    data={chat}
                    currentUserEmail={this.state.currentUserEmail}
                    requesteeResponse={this.state.requesteeResponse}
                    requestorResponse={this.state.requestorResponse}
                    isMobile={isMobile}
                  />
                </Grid>
              ))
            ) : (
              <Grid container item xs={6} sm={6} md={4} lg={3}>
                <EmptyCard
                  className={customStyles.cardLoading}
                  filtered={true}
                />
              </Grid>
            )
          ) : (
            <Grid container item xs={6} sm={6} md={4} lg={3}>
              <Skeleton
                variant="rectanglar"
                className={customStyles.cardLoading}
              />
            </Grid>
          )}
        </Grid>
      </InfiniteScroll>
    );
  };

  componentDidMount() {
    this.fetchConnects();
    this.fetchConnectRequests();
    this.fetchUsers();
  }

  render() {
    const customStyles = this.props.customStyles;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={this.props.theme}>
          <CssBaseline />
          <div className={customStyles.page}>
            <Typography variant="h1" style={{ marginBottom: "30px" }}>
              Members
            </Typography>

            {/*Filters*/}
            <div className={customStyles.filterContainer}>
              {/*Industry Dropdown */}
              <TextField
                id="outlined-select-education"
                fullWidth
                select
                label="Industry"
                value={this.state.industry}
                onChange={this.handleIndustryChange}
                variant="outlined"
                className={customStyles.textField}
              >
                {IndustryLabels.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {/*Toggle Button */}
              <ToggleButtonGroup
                value={this.state.alignment}
                exclusive
                aria-label="Member Type"
                onChange={this.handleAlignment}
              >
                <Tooltip title="Senior Execs are highlighted with golden rings">
                  <ToggleButton
                    value="SeniorExecutive"
                    aria-label="Senior Executive"
                    selected={this.state.toggleSeniorExcutive}
                    disabled={this.state.toggleSeniorExcutive}
                  >
                    <Typography variant="input" align="center">
                      Senior Executive
                    </Typography>
                  </ToggleButton>
                </Tooltip>
                <ToggleButton
                  value="GeneralMember"
                  aria-label="General Member"
                  selected={!this.state.toggleSeniorExcutive}
                  disabled={!this.state.toggleSeniorExcutive}
                >
                  <Typography variant="input" align="center">
                    General Member
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {/*Card*/}
            {this.returnCards()}
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

JobBoard = withRouter(JobBoard);
JobBoard = withMyHook(JobBoard);
export default withSnackbar(JobBoard);
