const Country = [
  {
    label: "Canada",
    value: "CA",
  },
  {
    label: "United States",
    value: "USA",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export default Country;
