import Attia_Afnan from "../Components/Images/senior/Attia_Afnan.jpg";
import Benkhouya_Saloua from "../Components/Images/senior/Benkhouya_Saloua.jpg";
import Ebrahim_Salima from "../Components/Images/senior/Ebrahim_Salima.jpg";
import Dorias_Osama from "../Components/Images/senior/Dorias_Osama.jpg";
import Gulam_Moosah from "../Components/Images/senior/Gulam_Moosah.jpg";
import Hashmi_Seema from "../Components/Images/senior/Hashmi_Seema.jpg";
import Jalaluddin_Uzma from "../Components/Images/senior/Jalaluddin_Uzma.jpg";
import Jama_Robleh from "../Components/Images/senior/Jama_Robleh.jpg";
import Khan_Samir from "../Components/Images/senior/Khan_Samir.jpg";
import Mahmood_Safdar from "../Components/Images/senior/Mahmood_Safdar.jpeg";
import Malik_Nadia from "../Components/Images/senior/Malik_Nadia.jpeg";
import Mitchell_Axelle from "../Components/Images/senior/Mitchell_Axelle.png";
import Rahman_Ebad from "../Components/Images/senior/Rahman_Ebad.jpg";
import Rooney_Richard from "../Components/Images/senior/Rooney_Richard.jpg";
import Salman_Zahid from "../Components/Images/senior/Salman_Zahid.jpg";
import Shahsamand_Asma from "../Components/Images/senior/Shahsamand_Asma.jpg";
import Soliman_Doha from "../Components/Images/senior/Soliman_Doha.jpg";
import Soliman_Osama from "../Components/Images/senior/Soliman_Osama.jpg";
import Soliman_Walied from "../Components/Images/senior/Soliman_Walied.jpg";
import Thomas_Benji from "../Components/Images/senior/Thomas_Benji.jpg";
import Yusuf_Danish from "../Components/Images/senior/Yusuf_Danish.jpg";
import Yusuf_Mustafa from "../Components/Images/senior/Yusuf_Mustafa.jpg";
import Zaman_Sadia from "../Components/Images/senior/Zaman_Sadia.jpg";
import Saba_Tariq from "../Components/Images/faceShot/Tariq_Saba.jpg";
import Yusuf_Moore from "../Components/Images/faceShot/Moore_Yusuf.jpg";
import Kefaya_Raji from "../Components/Images/faceShot/Raji_Kefaya.jpg";
import Abadhleh from "../Components/Images/faceShot/Al-Abadleh_Dr.Hind.jpg";
import Vajid from "../Components/Images/faceShot/Khan_Dr. Vajid.jpg";

export const seShowcaseImages = () => {
  return [
    {
      photo: Hashmi_Seema,
      name: "Seema Hashmi",
      title: "Chief of Staff to Group Head of Business Banking @ TD",
    },
    {
      photo: Thomas_Benji,
      name: "Benjie Thomas",
      title: "Canadian Managing Partner @ KPMG",
    },
    {
      photo: Soliman_Walied,
      name: "Walied Soliman",
      title: "Partner and Chair @ Norton Rose",
    },
    {
      photo: Dorias_Osama,
      name: "Osama Doria",
      title: "Lead Game Designer @ Warner Brothers",
    },
    {
      photo: Rooney_Richard,
      name: "Richard Rooney",
      title: "President and CIO @ Burgundy Asset Management",
    },
    {
      photo: Salman_Zahid,
      name: "Zahid Salman",
      title: "President & CEO @ Green Shield Canada",
    },
    {
      photo: Benkhouya_Saloua,
      name: "Saloua Benkhouya",
      title: "VP Business Financial Services @ RBC",
    },
    {
      photo: Shahsamand_Asma,
      name: "Asma Shahsamand",
      title: "Head of Canada Ventures @ Accenture",
    },
    {
      photo: Zaman_Sadia,
      name: "Sadia Zaman",
      title: "CEO @ Inspirit Foundation",
    },
    {
      photo: Gulam_Moosah,
      name: "Moosah Gulam",
      title:
        "President and CEO and Head of the Medical Technology Segment @ ZEISS Canada",
    },
    {
      photo: Rahman_Ebad,
      name: "Ebad Rahman",
      title: "Partner @ Torys",
    },
    {
      photo: Khan_Samir,
      name: "Samir Khan",
      title:
        "CFO & CCO for Canada and General Counsel for Americas @ Russell Investments",
    },
    {
      photo: Malik_Nadia,
      name: "Nadia Mailk",
      title: "Director Program Management & Corporate Initiatives @ Bombardier",
    },
    {
      photo: Jama_Robleh,
      name: "Robleh Jama",
      title: "Director @ Shopify",
    },
    {
      photo: Yusuf_Mustafa,
      name: "Mustafa Yusuf",
      title:
        "President & CoFounder @ FLOCK rotisserie+greens;President & Founder @ Big Smoke Burger",
    },
    {
      photo: Jalaluddin_Uzma,
      name: "Uzma Jalaluddin",
      title: "Author @ Ayesha At Last;Columnist @ Toronto Star",
    },
    {
      photo: Mahmood_Safdar,
      name: "Safdar Mahmood",
      title: "Managing Vice President @ Pariveda Solutions",
    },
    {
      photo: Ebrahim_Salima,
      name: "Salima Ebrahim",
      title: "Chief of Staff @ City of Edmonton",
    },
    {
      photo: Yusuf_Danish,
      name: "Danish Yusuf",
      title: "CEO and Founder @ Zensurance;Principal @ McKinsey",
    },
    {
      photo: Attia_Afnan,
      name: "Afnan Attia",
      title: "Director of Strategy and Corporate Development @ Morneau Shepell",
    },
    {
      photo: Soliman_Doha,
      name: "Doha Soliman",
      title:
        "Finance Director @ Blueridge OMS (Oral and Maxillofacial Surgeon)",
    },
    {
      photo: Soliman_Osama,
      name: "Osama Soliman",
      title: "Oral and Maxillofacial Surgeon @ Blueridge OMS",
    },
    {
      photo: Mitchell_Axelle,
      name: "Axelle Mitchel",
      title: "Director, Strategy & Transformation @ RBC",
    },
    {
      photo: Saba_Tariq,
      name: "Saba Tariq",
      title: "Partner @ Deloitte",
    },
    {
      photo: Yusuf_Moore,
      name: "Yusuf Moore",
      title: "General Counsel @ League Inc.",
    },
    {
      photo: Kefaya_Raji,
      name: "Kefaya Raji",
      title: "Head of HR",
    },
    {
      photo: Vajid,
      name: "Dr. Vajid Khan",
      title:
        "Diagnostic & Non-Vascular Interventional Radiologist @ Bluewater Health;Adjunct Professor @ University of Western Ontario",
    },
    {
      photo: Abadhleh,
      name: "Dr. Hind Al Abadleh",
      title:
        "Professor in Department of Chemistry and Biochemistry @ Wilfred Laurier Univeristy",
    },
  ];
};

export const testimonials = () => {
  return [
    {
      testimonial:
        "MAX has amazingly brought together an entire community and I eagerly look forward to meeting MAX Aspiring Professionals and doing my part to help their careers.",
      name: "Walied Soliman",
      role: "Partner and Global Chair",
      company: "Norton Rose Fulbright LLP",
      image_path: require("../Components/Images/faceShot/Soliman_Walied.jpg"),
      alt_text: "Walied Soliman Testimonial",
    },
    {
      testimonial:
        "MAX has made a tremendous contribution to the community by bringing people together to support and motivate aspiring professionals, and celebrate achievements of deserving individuals who are making contributions today and for the future of a diverse and inclusive Canada.",
      name: "Salma Salman",
      role: "CFO of Wealth Finance",
      company: "TD Bank Group",
      image_path: require("../Components/Images/faceShot/Salman_Salma.jpg"),
      alt_text: "Salma Salman Testimonial",
    },
    {
      testimonial:
        "I know first-hand the power of mentorship to drive personal and professional growth. That’s why I joined MAX Aspire: to share my experience and encourage and support Canada’s next generation of leaders and impact-makers.",
      name: "Dr. Mohamed Lachemi",
      role: "President and Vice Chancellor",
      company: "Ryerson University",
      image_path: require("../Components/Images/faceShot/Lachemi_Dr.Mohamed.jpg"),
      alt_text: "Dr. Mohamed Lachemi Testimonial",
    },
    {
      testimonial:
        "I am pleased to be able to give back and guide young professionals on MAX Aspire. MAX is a thoughtful organization looking to inspire and enable Muslims to be strong, positive and productive contributors to society.",
      name: "Robleh Jama",
      role: "Director of Product",
      company: "Shopify",
      image_path: require("../Components/Images/faceShot/Jama_Robleh.jpg"),
      alt_text: "Robleh Jama Testimonial",
    },
    {
      testimonial:
        "Kudos to the MAX team for leading a one-of-a kind organization that celebrates and mentors Muslim professionals aspiring to become the best in their respective fields.",
      name: "Dr. Hind Al-Abadleh",
      role: "Professor in Department of Chemistry and Biochemistry",
      company: "Wilfrid Laurier University",
      image_path: require("../Components/Images/faceShot/Al-Abadleh_Dr.Hind.jpg"),
      alt_text: "Dr. Hind Al-Abadleh Testimonial",
    },
    {
      testimonial:
        "God willing, the Max Aspire platform will enable you to steepen your learning curve and leapfrog your professional & personal life.",
      name: "Muz Parkhani",
      role: "Director, Global Thematic Return",
      company: "OMERS",
      image_path: require("../Components/Images/faceShot/Parkhani_Muzammil.png"),
      alt_text: "Muz Parkhani Testimonial",
    },
    {
      testimonial:
        "MAX’s admirable mission of accelerating the advancement of students and professionals in our community has produced tremendous results already in a short period. I am excited to contribute further inspiration and leadership to help MAX Aspire members broaden their networks and grow both personally and professionally.",
      name: "Ali Murtaza",
      role: "IoT Sales Leader for Canada",
      company: "Amazon Web Services",
      image_path: require("../Components/Images/faceShot/Murtaza_Ali.jpg"),
      alt_text: "Ali Murtaza Testimonial",
    },
  ];
};
